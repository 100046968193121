import React from 'react';
import { Facebook, Instagram, Linkedin } from 'lucide-react';
import { Link } from 'react-router-dom';
import logo7 from '/src/logo/7.svg';

export const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Logo and Slogan Section */}
          <div className="flex flex-col items-center md:items-start">
            <div className="mb-3 relative w-full max-w-[160px]">
              <img 
                src={logo7}
                alt="Cipher Leads Logo"
                className="w-full h-auto object-contain"
              />
            </div>
            <p className="text-gray-400 text-sm md:text-base font-light leading-relaxed max-w-[280px] text-center md:text-left">
              Empowering businesses with AI-driven lead generation solutions.
            </p>
          </div>
          
          {/* Quick Links Section */}
          <div className="text-center md:text-left">
            <h3 className="text-xl font-bold mb-4 text-white/90">Quick Links</h3>
            <ul className="space-y-3">
              <li>
                <a 
                  href="https://tidycal.com/hyperlinq/30-minutes-free-good-sales-meeting" 
                  className="text-gray-400 hover:text-white transition-colors duration-200"
                >
                  Book Strategy Call
                </a>
              </li>
              <li>
                <a 
                  href="#services" 
                  className="text-gray-400 hover:text-white transition-colors duration-200"
                >
                  Services
                </a>
              </li>
              <li>
                <a 
                  href="#benefits" 
                  className="text-gray-400 hover:text-white transition-colors duration-200"
                >
                  Benefits
                </a>
              </li>
              <li>
                <Link 
                  to="/login" 
                  className="text-gray-400 hover:text-white transition-colors duration-200"
                >
                  Login
                </Link>
              </li>
            </ul>
          </div>
          
          {/* Social Links Section */}
          <div className="text-center md:text-left">
            <h3 className="text-xl font-bold mb-4 text-white/90">Connect With Us</h3>
            <div className="flex justify-center md:justify-start space-x-6">
              <a 
                href="https://www.facebook.com/marketingwithshoaib" 
                className="text-gray-400 hover:text-white transition-colors duration-200"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook size={28} className="hover:scale-110 transition-transform duration-200" />
              </a>
              <a 
                href="https://www.instagram.com/marketingwithshoaib/" 
                className="text-gray-400 hover:text-white transition-colors duration-200"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram size={28} className="hover:scale-110 transition-transform duration-200" />
              </a>
              <a 
                href="https://www.linkedin.com/in/marketingwithshoaib/" 
                className="text-gray-400 hover:text-white transition-colors duration-200"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Linkedin size={28} className="hover:scale-110 transition-transform duration-200" />
              </a>
            </div>
          </div>
        </div>
        
        {/* Copyright Section */}
        <div className="mt-12 pt-8 border-t border-gray-800 text-center">
          <p className="text-gray-400 text-sm">
            &copy; {new Date().getFullYear()} Cipher Leads. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};