import React, { createContext, useContext, useEffect, useState } from 'react';
import { User, AuthResponse } from '@supabase/supabase-js';
import { supabase } from '../lib/supabaseClient';

// Define the shape of the AuthContext
interface AuthContextType {
  user: User | null;
  userType: 'frontend' | 'backend' | null;
  signUp: (data: { email: string; password: string; options?: { data: any } }) => Promise<AuthResponse>;
  signIn: (data: { email: string; password: string }) => Promise<AuthResponse>;
  signOut: () => Promise<void>;
  loading: boolean;
}

// Create the context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Provider component
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [userType, setUserType] = useState<'frontend' | 'backend' | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Check active sessions and subscribe to auth changes
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user ?? null);
      setUserType(session?.user?.user_metadata?.user_type ?? null);
      setLoading(false);
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null);
      setUserType(session?.user?.user_metadata?.user_type ?? null);
      setLoading(false);
    });

    return () => subscription.unsubscribe();
  }, []);

  // Sign up function
  const signUp = async (data: { email: string; password: string; options?: { data: any } }) => {
    setLoading(true);
    try {
      // Validate input
      if (!data.email || !data.password) {
        throw new Error('Email and password are required');
      }

      // Validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(data.email)) {
        throw new Error('Invalid email format');
      }

      // Validate password strength
      if (data.password.length < 8) {
        throw new Error('Password must be at least 8 characters');
      }
      if (!/(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])/.test(data.password)) {
        throw new Error('Password must include uppercase, lowercase, number, and special character');
      }

      // Ensure user_type is set in metadata
      const signupData = {
        ...data,
        options: {
          ...data.options,
          data: {
            ...data.options?.data,
            user_type: data.options?.data?.user_type || 'frontend',
            created_at: new Date().toISOString()
          }
        }
      };

      const response = await supabase.auth.signUp(signupData);

      // Handle potential signup errors
      if (response.error) {
        if (response.error.message.includes('User already registered')) {
          throw new Error('Account already exists. Please log in.');
        }
        throw response.error;
      }

      return response;
    } catch (error) {
      console.error('Sign Up Error:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Sign in function
  const signIn = async (data: { email: string; password: string }) => {
    setLoading(true);
    try {
      const response = await supabase.auth.signInWithPassword(data);
      if (response.data.user) {
        setUserType(response.data.user.user_metadata?.user_type ?? null);
      }
      return response;
    } catch (error) {
      console.error('Sign In Error:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Sign out function
  const signOut = async () => {
    setLoading(true);
    try {
      await supabase.auth.signOut();
      setUser(null);
      setUserType(null);
    } catch (error) {
      console.error('Sign Out Error:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const value = {
    user,
    userType,
    signUp,
    signIn,
    signOut,
    loading
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the auth context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
