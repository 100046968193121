import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sparkles, LogOut, User } from 'lucide-react';
import { useAuth } from '../context/AuthContext';

export const UserDashboard: React.FC = () => {
  const { user, userType, signOut } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    // Redirect backend users to admin dashboard
    if (userType === 'backend') {
      navigate('/dashboard');
      return;
    }
  }, [user, userType, navigate]);

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Sign out error:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      {/* Sidebar */}
      <div className="w-64 bg-white border-r border-gray-200 py-8 px-6">
        <div className="flex items-center mb-10">
          <Sparkles className="h-8 w-8 text-blue-600 mr-3" />
          <h1 className="text-2xl font-bold text-gray-900">AI Cipher</h1>
        </div>

        <nav className="space-y-2">
          <button 
            className="w-full flex items-center space-x-3 px-4 py-2 text-gray-700 hover:bg-blue-50 hover:text-blue-600 rounded-lg transition-colors font-medium"
          >
            <User className="h-5 w-5" />
            <span>My Profile</span>
          </button>
          <button 
            onClick={handleSignOut}
            className="w-full flex items-center space-x-3 px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors font-medium"
          >
            <LogOut className="h-5 w-5" />
            <span>Logout</span>
          </button>
        </nav>
      </div>

      {/* Main Content Area */}
      <div className="flex-1 flex flex-col">
        {/* Header */}
        <header className="bg-white border-b border-gray-200 py-4 px-6">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold text-gray-900">
              Welcome, 👋 {user?.user_metadata?.full_name || user?.email || 'User'}
            </h2>
          </div>
        </header>

        {/* Main Content */}
        <main className="p-6 flex-1">
          <div className="bg-white shadow-md rounded-lg p-6">
            <h3 className="text-lg font-semibold mb-4">Your Profile</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-600">Name</label>
                <p className="mt-1 text-gray-900">{user?.user_metadata?.full_name || 'Not set'}</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-600">Email</label>
                <p className="mt-1 text-gray-900">{user?.email}</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-600">Account Type</label>
                <p className="mt-1 text-gray-900 capitalize">{userType || 'Standard'} User</p>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
