import { Helmet } from 'react-helmet-async';
import { HeroSection } from '../components/HeroSection';
import { ServiceCard } from '../components/ServiceCard';
import { StatsSection } from '../components/StatsSection';
import { CTASection } from '../components/CTASection';
import { TestimonialSection } from '../components/TestimonialSection';
import { FAQSection } from '../components/FAQSection';
import { Brain, Target, BarChart3 } from 'lucide-react';

interface HomePageProps {
  onLeadMagnetClick: () => void;
}

export const HomePage = ({ onLeadMagnetClick }: HomePageProps) => {
  const services = [
    {
      icon: Brain,
      title: 'Lead Discovery',
      description: 'AI-powered lead identification and qualification to find your ideal prospects.',
      link: '/services/lead-discovery'
    },
    {
      icon: Target,
      title: 'Digital Growth',
      description: 'Strategic digital marketing solutions to expand your market presence.',
      link: '/services/digital-growth'
    },
    {
      icon: BarChart3,
      title: 'Conversion Boost',
      description: 'Data-driven optimization to transform leads into valuable clients.',
      link: '/services/conversion-boost'
    }
  ];

  return (
    <>
      <Helmet>
        <title>AI Lead Generation Services | Cipher Leads - AI Lead Generation Agency</title>
        <meta name="description" content="Transform your B2B lead generation with HyperLinq's AI-powered solutions. Discover how our advanced technology can help you generate high-quality leads." />
        <meta name="keywords" content="AI lead generation, B2B lead generation agency, lead generation services" />
      </Helmet>

      <HeroSection onLeadMagnetClick={onLeadMagnetClick} />
      
      <section id="services" className="py-20 px-4 sm:px-6 lg:px-8 bg-gray-50">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold mb-4 text-gray-900">Our Specialized Services</h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Discover how our AI-powered solutions can transform your lead generation process
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            {services.map((service) => (
              <ServiceCard key={service.title} {...service} />
            ))}
          </div>
        </div>
      </section>

      <section id="stats">
        <StatsSection />
      </section>
      
      <section id="testimonials">
        <TestimonialSection />
      </section>
      
      <section id="faq">
        <FAQSection />
      </section>
      
      <CTASection onLeadMagnetClick={onLeadMagnetClick} />
    </>
  );
};