import React, { Suspense } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import { ExitIntent } from './components/ExitIntent';
import { LeadMagnetPopup } from './components/LeadMagnetPopup';
import { HomePage } from './pages/HomePage';
import { ServicePage } from './pages/ServicePage';
import { ContactPage } from './pages/ContactPage';
import { DashboardPage } from './pages/DashboardPage';
import Login from './pages/Login';
import SignupPage from './pages/SignupPage';
import { UserDashboard } from './pages/UserDashboard';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import { AuthProvider } from './context/AuthContext';
import { useLeadMagnet } from './hooks/useLeadMagnet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Loading component
const Loading = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
  </div>
);

function App() {
  const location = useLocation();
  const { showLeadMagnet, setShowLeadMagnet } = useLeadMagnet();

  // Don't show navbar and footer on dashboard or auth pages
  const isDashboardOrAuthPage = 
    location.pathname === '/dashboard' || 
    location.pathname === '/login' || 
    location.pathname === '/signup' ||
    location.pathname === '/user-dashboard' ||
    location.pathname === '/forgot-password' ||
    location.pathname === '/reset-password';

  const showNavbarAndFooter = !isDashboardOrAuthPage;

  return (
    <AuthProvider>
      <div className="flex flex-col min-h-screen">
        {showNavbarAndFooter && (
          <Navbar onLeadMagnetClick={() => setShowLeadMagnet(true)} />
        )}
        
        <Suspense fallback={<Loading />}>
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<HomePage onLeadMagnetClick={() => setShowLeadMagnet(true)} />} />
            <Route path="/services/:service" element={<ServicePage />} />
            <Route path="/contact" element={<ContactPage />} />
            
            {/* Auth Routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            
            {/* Protected Dashboard Routes */}
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/user-dashboard" element={<UserDashboard />} />
          </Routes>
        </Suspense>

        {showNavbarAndFooter && <Footer />}
        
        {/* Lead Magnet Popup */}
        <LeadMagnetPopup 
          isOpen={showLeadMagnet} 
          onClose={() => setShowLeadMagnet(false)} 
        />

        <ToastContainer 
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </AuthProvider>
  );
}

export default App;