import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

// Import logos with public path
import logo6 from '/src/logo/6.svg';
import logo7 from '/src/logo/7.svg';

interface NavbarProps {
  onLeadMagnetClick: () => void;
}

export const Navbar = ({ onLeadMagnetClick }: NavbarProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`fixed w-full z-50 transition-all duration-300 ${
      isScrolled ? 'bg-white/80 backdrop-blur-lg shadow-lg' : 'bg-transparent'
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-20">
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              <img 
                src={isScrolled ? logo6 : logo7}
                alt="Cipher Leads Logo"
                className="h-24 w-auto"
              />
            </Link>
          </div>
          
          <div className="hidden md:flex items-center space-x-8">
            <a href="#services" className={`transition-colors ${
              isScrolled ? 'text-gray-700 hover:text-blue-600' : 'text-white/90 hover:text-white'
            }`}>
              Services
            </a>
            <a href="#testimonials" className={`transition-colors ${
              isScrolled ? 'text-gray-700 hover:text-blue-600' : 'text-white/90 hover:text-white'
            }`}>
              Testimonials
            </a>
            <a href="#faq" className={`transition-colors ${
              isScrolled ? 'text-gray-700 hover:text-blue-600' : 'text-white/90 hover:text-white'
            }`}>
              FAQ
            </a>
            <button 
              onClick={onLeadMagnetClick}
              className={`px-4 py-2 rounded-full transition-all ${
                isScrolled 
                  ? 'bg-blue-600 text-white hover:bg-blue-700' 
                  : 'bg-white/20 text-white hover:bg-white/30'
              }`}
            >
              Get Started
            </button>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden flex items-center">
            <button 
              onClick={() => setIsOpen(!isOpen)}
              className={`${
                isScrolled ? 'text-gray-700' : 'text-white'
              } focus:outline-none`}
            >
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <a 
              href="#services" 
              className={`block px-3 py-2 rounded-md ${
                isScrolled 
                  ? 'text-gray-700 hover:bg-gray-200' 
                  : 'text-white hover:bg-white/20'
              }`}
            >
              Services
            </a>
            <a 
              href="#testimonials" 
              className={`block px-3 py-2 rounded-md ${
                isScrolled 
                  ? 'text-gray-700 hover:bg-gray-200' 
                  : 'text-white hover:bg-white/20'
              }`}
            >
              Testimonials
            </a>
            <a 
              href="#faq" 
              className={`block px-3 py-2 rounded-md ${
                isScrolled 
                  ? 'text-gray-700 hover:bg-gray-200' 
                  : 'text-white hover:bg-white/20'
              }`}
            >
              FAQ
            </a>
            <button 
              onClick={onLeadMagnetClick}
              className={`w-full px-4 py-2 rounded-full transition-all ${
                isScrolled 
                  ? 'bg-blue-600 text-white hover:bg-blue-700' 
                  : 'bg-white/20 text-white hover:bg-white/30'
              }`}
            >
              Get Started
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};