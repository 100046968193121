import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Brain, Target, BarChart3, Check } from 'lucide-react';
import { CTAButton } from '../components/CTAButton';

const services = {
  'lead-discovery': {
    icon: Brain,
    title: 'Lead Discovery',
    description: 'AI-powered lead identification and qualification to find your ideal prospects.',
    benefits: [
      'AI-driven prospect identification',
      'Advanced data analytics',
      'Real-time lead scoring',
      'Automated lead qualification',
      'Market intelligence insights'
    ]
  },
  'digital-growth': {
    icon: Target,
    title: 'Digital Growth',
    description: 'Strategic digital marketing solutions to expand your market presence.',
    benefits: [
      'Multi-channel marketing strategies',
      'Content optimization',
      'Social media engagement',
      'Marketing automation',
      'Performance analytics'
    ]
  },
  'conversion-boost': {
    icon: BarChart3,
    title: 'Conversion Boost',
    description: 'Data-driven optimization to transform leads into valuable clients.',
    benefits: [
      'Conversion rate optimization',
      'A/B testing',
      'User journey analysis',
      'Behavioral analytics',
      'ROI tracking'
    ]
  }
};

export const ServicePage = () => {
  const { service } = useParams();
  const serviceData = services[service as keyof typeof services];
  const Icon = serviceData?.icon;

  if (!serviceData) return <div>Service not found</div>;

  return (
    <>
      <Helmet>
        <title>{serviceData.title} | HyperLinq - AI Lead Generation Services</title>
        <meta name="description" content={serviceData.description} />
      </Helmet>

      <div className="pt-24 pb-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <div className="w-20 h-20 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-6">
              <Icon className="w-10 h-10 text-blue-600" />
            </div>
            <h1 className="text-4xl font-bold text-gray-900 mb-4">{serviceData.title}</h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              {serviceData.description}
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-12">
            <div className="bg-white p-8 rounded-2xl shadow-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Key Benefits</h2>
              <ul className="space-y-4">
                {serviceData.benefits.map((benefit) => (
                  <li key={benefit} className="flex items-center gap-3">
                    <Check className="w-5 h-5 text-blue-600 flex-shrink-0" />
                    <span className="text-gray-700">{benefit}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="bg-gradient-to-br from-blue-600 to-blue-700 p-8 rounded-2xl text-white">
              <h2 className="text-2xl font-bold mb-6">Ready to Get Started?</h2>
              <p className="mb-8">
                Transform your lead generation strategy with our {serviceData.title.toLowerCase()} service. Book your free strategy session today!
              </p>
              <CTAButton
                text="Book Free Strategy Call"
                variant="white"
                className="w-full text-center"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};