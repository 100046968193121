import React, { useState, useEffect } from 'react';
import { supabase, updateLead, deleteLead } from '../lib/supabaseClient';
import { Toaster, toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import {
  Bell,
  UserCircle,
  Home,
  Settings,
  LogOut,
  PencilLine,
  Trash2,
  Search,
  ChevronLeft,
  ChevronRight,
  Loader2,
  Check,
  X
} from 'lucide-react';

export const DashboardPage: React.FC = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [leads, setLeads] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [notifications, setNotifications] = useState<string[]>([]);
  const [editingLead, setEditingLead] = useState<{ id: string; field: string } | null>(null);
  const [editValues, setEditValues] = useState<{ [key: string]: string }>({});

  const addNotification = (message: string) => {
    setNotifications((prev) => {
      if (!prev.includes(message)) {
        return [message, ...prev.slice(0, 4)];
      }
      return prev;
    });
  };

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/');
      toast.success('Signed out successfully');
    } catch (error: any) {
      toast.error(error.message || 'Error signing out');
    }
  };

  const handleEdit = async (leadId: string) => {
    try {
      const leadToEdit = leads.find(lead => lead.id === leadId);
      if (!leadToEdit) {
        toast.error('Lead not found');
        return;
      }
      
      setEditingLead({ id: leadId, field: 'name' });
      setEditValues({
        name: leadToEdit.name || '',
        email: leadToEdit.email || '',
        phone: leadToEdit.phone || ''
      });
    } catch (error: any) {
      toast.error('Error starting edit');
    }
  };

  const handleSaveEdit = async () => {
    if (!editingLead) return;

    try {
      // Validate input
      if (!editValues.name?.trim()) {
        toast.error('Name is required');
        return;
      }
      if (!editValues.email?.trim()) {
        toast.error('Email is required');
        return;
      }

      // Prepare update data
      const updateData = {
        name: editValues.name.trim(),
        email: editValues.email.trim(),
        phone: editValues.phone?.trim() || null
      };

      // Log the update attempt
      console.log('Attempting to update lead:', {
        leadId: editingLead.id,
        updateData
      });

      // Validate lead ID before update
      if (!editingLead.id) {
        toast.error('Invalid lead ID');
        return;
      }

      // Update lead using utility function
      const { data, error } = await updateLead(editingLead.id, updateData);

      // Enhanced error handling
      if (error) {
        console.error('Update Lead Error:', error);
        toast.error(error || 'Failed to update lead');
        return;
      }

      // Update local state if data is returned
      if (data) {
        setLeads(prevLeads => 
          prevLeads.map(lead => 
            lead.id === editingLead.id ? { ...lead, ...data } : lead
          )
        );

        setEditingLead(null);
        setEditValues({});
        toast.success('Lead updated successfully');
      } else {
        // If no data returned, but also no error
        console.warn('Update successful, but no data returned');
        toast.success('Lead updated successfully');
        
        // Manually update local state with original update data
        setLeads(prevLeads => 
          prevLeads.map(lead => 
            lead.id === editingLead.id ? { ...lead, ...updateData } : lead
          )
        );

        setEditingLead(null);
        setEditValues({});
      }
    } catch (error: any) {
      console.error('Unexpected edit error:', error);
      toast.error(error.message || 'An unexpected error occurred');
    }
  };

  const handleDeleteLead = async (leadId: string) => {
    try {
      // Validate lead ID before delete
      if (!leadId) {
        toast.error('Invalid lead ID');
        return;
      }

      // Log the delete attempt
      console.log('Attempting to delete lead:', { leadId });

      // Delete lead using utility function
      const { error } = await deleteLead(leadId);

      // Enhanced error handling
      if (error) {
        console.error('Delete Lead Error:', error);
        toast.error(error || 'Failed to delete lead');
        return;
      }

      // Update local state
      setLeads(prevLeads => prevLeads.filter(lead => lead.id !== leadId));
      toast.success('Lead deleted successfully');
    } catch (error: any) {
      console.error('Unexpected delete error:', error);
      toast.error(error.message || 'An unexpected error occurred');
    }
  };

  const cancelEdit = () => {
    setEditingLead(null);
    setEditValues({});
  };

  const saveLeadEdit = async () => {
    if (!editingLead) return;

    try {
      const { data, error } = await supabase
        .from('leads')
        .update({ [editingLead.field]: editValues[editingLead.field] })
        .eq('id', editingLead.id)
        .select();

      if (error) throw error;

      setLeads((prevLeads) =>
        prevLeads.map((lead) =>
          lead.id === editingLead.id
            ? { ...lead, [editingLead.field]: editValues[editingLead.field] }
            : lead
        )
      );
      addNotification('Lead updated successfully');
      toast.success('Lead updated successfully');
      setEditingLead(null);
    } catch (err) {
      console.error('Error updating lead:', err);
      toast.error('Failed to update lead');
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data: userData } = await supabase.auth.getUser();
      if (!userData.user) throw new Error('No authenticated user');
      setUser(userData.user);

      let query = supabase
        .from('leads')
        .select('*', { count: 'exact' })
        .order('created_at', { ascending: false });

      if (searchQuery) {
        query = query.or(`name.ilike.%${searchQuery}%,email.ilike.%${searchQuery}%,phone.ilike.%${searchQuery}%`);
      }

      const { data, count, error } = await query.range((currentPage - 1) * 10, currentPage * 10 - 1);
      
      if (error) throw error;

      setLeads(data || []);
      setTotalPages(Math.ceil((count || 0) / 10));
    } catch (err) {
      console.error('Error fetching data:', err);
      toast.error('Error fetching leads');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, searchQuery]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-blue-100">
      <div className="flex h-screen">
        {/* Sidebar */}
        <div className="w-64 bg-white shadow-2xl">
          <div className="p-6">
          <img src="/src/logo/6.svg" alt="Cipher Leads" className="h-17 w-auto mb-8" />
            <nav className="space-y-4">
              <a href="#" className="flex items-center space-x-3 text-blue-600 bg-blue-50 p-3 rounded-lg">
                <Home className="h-5 w-5" />
                <span>Dashboard</span>
              </a>
              <a href="#" className="flex items-center space-x-3 text-gray-600 hover:bg-gray-50 p-3 rounded-lg transition duration-300">
                <Settings className="h-5 w-5" />
                <span>Settings</span>
              </a>
              <button onClick={handleSignOut} className="flex items-center space-x-3 text-gray-600 hover:bg-gray-50 p-3 rounded-lg transition duration-300 w-full">
                <LogOut className="h-5 w-5" />
                <span>Sign Out</span>
              </button>
            </nav>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 flex flex-col">
          {/* Header */}
          <header className="bg-white shadow-md p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <div className="relative">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Search leads..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300"
                  />
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <button className="relative p-2 text-gray-600 hover:bg-gray-100 rounded-full">
                  <Bell className="h-6 w-6" />
                  {notifications.length > 0 && (
                    <span className="absolute top-0 right-0 h-4 w-4 bg-red-500 rounded-full text-xs text-white flex items-center justify-center">
                      {notifications.length}
                    </span>
                  )}
                </button>
                <div className="flex items-center space-x-2">
                  <UserCircle className="h-8 w-8 text-gray-600" />
                  <span className="text-sm text-gray-700">{user?.user_metadata?.name || 'User'}</span>
                </div>
              </div>
            </div>
          </header>

          {/* Main Content Area */}
          <main className="flex-1 p-6 overflow-auto">
            <div className="bg-white rounded-2xl shadow-xl p-6">
              <h2 className="text-2xl font-semibold text-gray-800 mb-6">Leads</h2>
              {loading ? (
                <div className="flex items-center justify-center py-8">
                  <Loader2 className="h-8 w-8 text-blue-500 animate-spin" />
                </div>
              ) : (
                <>
                  <div className="overflow-x-auto">
                    <table className="w-full">
                      <thead>
                        <tr className="border-b border-gray-200">
                          <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">Name</th>
                          <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">Email</th>
                          <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">Phone</th>
                          <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {leads.map((lead) => (
                          <tr key={lead.id} className="border-b border-gray-100 hover:bg-gray-50">
                            <td className="px-4 py-3">
                              {editingLead?.id === lead.id ? (
                                <input
                                  type="text"
                                  value={editValues.name || ''}
                                  onChange={(e) => setEditValues({ ...editValues, name: e.target.value })}
                                  className="w-full px-2 py-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                  placeholder="Enter name"
                                />
                              ) : (
                                lead.name
                              )}
                            </td>
                            <td className="px-4 py-3">
                              {editingLead?.id === lead.id ? (
                                <input
                                  type="email"
                                  value={editValues.email || ''}
                                  onChange={(e) => setEditValues({ ...editValues, email: e.target.value })}
                                  className="w-full px-2 py-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                  placeholder="Enter email"
                                />
                              ) : (
                                lead.email
                              )}
                            </td>
                            <td className="px-4 py-3">
                              {editingLead?.id === lead.id ? (
                                <input
                                  type="tel"
                                  value={editValues.phone || ''}
                                  onChange={(e) => setEditValues({ ...editValues, phone: e.target.value })}
                                  className="w-full px-2 py-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                  placeholder="Enter phone"
                                />
                              ) : (
                                lead.phone
                              )}
                            </td>
                            <td className="px-4 py-3">
                              <div className="flex space-x-2">
                                {editingLead?.id === lead.id ? (
                                  <>
                                    <button 
                                      onClick={handleSaveEdit}
                                      className="p-2 text-green-600 hover:bg-green-50 rounded-lg transition duration-300"
                                    >
                                      <Check className="h-5 w-5" />
                                    </button>
                                    <button 
                                      onClick={cancelEdit}
                                      className="p-2 text-gray-600 hover:bg-gray-50 rounded-lg transition duration-300"
                                    >
                                      <X className="h-5 w-5" />
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button 
                                      onClick={() => handleEdit(lead.id)}
                                      className="p-2 text-blue-600 hover:bg-blue-50 rounded-lg transition duration-300"
                                    >
                                      <PencilLine className="h-5 w-5" />
                                    </button>
                                    <button 
                                      onClick={() => handleDeleteLead(lead.id)}
                                      className="p-2 text-red-600 hover:bg-red-50 rounded-lg transition duration-300"
                                    >
                                      <Trash2 className="h-5 w-5" />
                                    </button>
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  
                  {/* Pagination */}
                  <div className="flex items-center justify-between mt-6">
                    <button
                      onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                      disabled={currentPage === 1}
                      className="flex items-center space-x-2 px-4 py-2 text-sm text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200 disabled:opacity-50 disabled:cursor-not-allowed transition duration-300"
                    >
                      <ChevronLeft className="h-4 w-4" />
                      <span>Previous</span>
                    </button>
                    <span className="text-sm text-gray-600">
                      Page {currentPage} of {totalPages}
                    </span>
                    <button
                      onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                      disabled={currentPage === totalPages}
                      className="flex items-center space-x-2 px-4 py-2 text-sm text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200 disabled:opacity-50 disabled:cursor-not-allowed transition duration-300"
                    >
                      <span>Next</span>
                      <ChevronRight className="h-4 w-4" />
                    </button>
                  </div>
                </>
              )}
            </div>
          </main>
        </div>
      </div>
      <Toaster position="top-right" />
    </div>
  );
};
