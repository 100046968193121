import React, { useState } from 'react';
import { X, BookOpen, Check } from 'lucide-react';
import { supabase } from '../lib/supabaseClient';
import { toast } from 'sonner';

interface LeadMagnetPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export const LeadMagnetPopup: React.FC<LeadMagnetPopupProps> = ({ isOpen, onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errors, setErrors] = useState<{name?: string; email?: string; phone?: string}>({});

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email.trim());
  };

  const validatePhone = (phone: string) => {
    const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    return phoneRegex.test(phone.trim());
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors({});

    try {
      // Validate inputs first
      const validationErrors: {name?: string; email?: string; phone?: string} = {};
      
      if (!name.trim()) {
        validationErrors.name = 'Name is required';
      }
      
      if (!email.trim()) {
        validationErrors.email = 'Email is required';
      } else if (!validateEmail(email)) {
        validationErrors.email = 'Please enter a valid email';
      }
      
      if (phone.trim() && !validatePhone(phone)) {
        validationErrors.phone = 'Please enter a valid phone number';
      }
      
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        setIsSubmitting(false);
        return;
      }

      // Check if lead already exists
      const { data: existingLeads, error: existingError } = await supabase
        .from('leads')
        .select('*')
        .eq('email', email.trim())
        .maybeSingle();

      if (existingError) {
        console.error('Existing lead check error:', existingError);
        throw existingError;
      }

      // If lead exists, don't insert again
      if (existingLeads) {
        toast.info('Thank you for your interest!', {
          description: 'We already have your information.'
        });
        setIsSubmitting(false);
        onClose();
        return;
      }

      // Insert lead with more detailed logging
      const { data, error: insertError } = await supabase
        .from('leads')
        .insert({
          name: name.trim(),
          email: email.trim(),
          phone: phone?.trim() || null,
          source: 'lead-magnet',
          status: 'new',
          created_at: new Date().toISOString()
        })
        .select();

      // Log full error details
      if (insertError) {
        console.error('Full Supabase Insert Error:', {
          message: insertError.message,
          details: insertError.details,
          hint: insertError.hint,
          code: insertError.code
        });
        throw insertError;
      }

      // Show success message
      setShowSuccessMessage(true);

      // Reset form and close popup after delay
      setTimeout(() => {
        setName('');
        setEmail('');
        setPhone('');
        setErrors({});
        setShowSuccessMessage(false);
        onClose();
      }, 5000);

    } catch (error: any) {
      console.error('Lead form submission error:', error);
      
      const errorMessage = error.message || 
        (error.error ? error.error.message : 'Please try again later');
      
      toast.error('Failed to submit form', {
        description: errorMessage
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  // If success message is showing, render a different view
  if (showSuccessMessage) {
    return (
      <div 
        className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
        onClick={onClose}
      >
        <div 
          className="bg-white rounded-2xl p-8 max-w-md w-full relative animate-fadeIn text-center"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="bg-green-100 rounded-full w-16 h-16 flex items-center justify-center mb-6 mx-auto">
            <Check className="w-8 h-8 text-green-600" />
          </div>
          
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Success!
          </h2>
          
          <p className="text-gray-600 mb-6">
            Your free guide will be sent to {email} shortly.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div 
      className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div 
        className="bg-white rounded-2xl p-8 max-w-md w-full relative animate-fadeIn"
        onClick={(e) => e.stopPropagation()}
      >
        <button 
          onClick={onClose} 
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
          aria-label="Close popup"
        >
          <X size={24} />
        </button>

        <div className="bg-blue-50 rounded-full w-16 h-16 flex items-center justify-center mb-6 mx-auto">
          <BookOpen className="w-8 h-8 text-blue-600" />
        </div>
        
        <h2 className="text-2xl font-bold text-gray-900 mb-2 text-center">
          Get Your Free Qualified Lead Generation Playbook
        </h2>
        
        <p className="text-gray-600 mb-6 text-center">
          Discover the exact strategies we use to generate high-quality leads using AI technology.
        </p>

        <form 
          onSubmit={handleSubmit} 
          className="space-y-4"
          autoComplete="off"
        >
          <div>
            <label htmlFor="lead-name" className="block text-sm font-medium text-gray-700 mb-1">
              Full Name
            </label>
            <input
              type="text"
              id="lead-name"
              name="lead-name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setErrors(prev => ({ ...prev, name: undefined }));
              }}
              className={`w-full px-4 py-3 rounded-lg border ${
                errors.name ? 'border-red-500' : 'border-gray-300'
              } focus:ring-2 focus:ring-blue-500`}
              placeholder="Enter your full name"
              required
              disabled={isSubmitting}
              aria-invalid={!!errors.name}
              aria-describedby={errors.name ? 'name-error' : undefined}
              maxLength={100}
              autoComplete="off"
            />
            {errors.name && (
              <p id="name-error" className="text-red-500 text-sm mt-1">
                {errors.name}
              </p>
            )}
          </div>

          <div>
            <label htmlFor="lead-email" className="block text-sm font-medium text-gray-700 mb-1">
              Email Address
            </label>
            <input
              type="email"
              id="lead-email"
              name="lead-email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrors(prev => ({ ...prev, email: undefined }));
              }}
              className={`w-full px-4 py-3 rounded-lg border ${
                errors.email ? 'border-red-500' : 'border-gray-300'
              } focus:ring-2 focus:ring-blue-500`}
              placeholder="Enter your email"
              required
              disabled={isSubmitting}
              aria-invalid={!!errors.email}
              aria-describedby={errors.email ? 'email-error' : undefined}
              maxLength={100}
              autoComplete="off"
            />
            {errors.email && (
              <p id="email-error" className="text-red-500 text-sm mt-1">
                {errors.email}
              </p>
            )}
          </div>

          <div>
            <label htmlFor="lead-phone" className="block text-sm font-medium text-gray-700 mb-1">
              Phone Number (Optional)
            </label>
            <input
              type="tel"
              id="lead-phone"
              name="lead-phone"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
                setErrors(prev => ({ ...prev, phone: undefined }));
              }}
              className={`w-full px-4 py-3 rounded-lg border ${
                errors.phone ? 'border-red-500' : 'border-gray-300'
              } focus:ring-2 focus:ring-blue-500`}
              placeholder="Enter your phone number"
              disabled={isSubmitting}
              aria-invalid={!!errors.phone}
              aria-describedby={errors.phone ? 'phone-error' : undefined}
              maxLength={20}
              autoComplete="off"
            />
            {errors.phone && (
              <p id="phone-error" className="text-red-500 text-sm mt-1">
                {errors.phone}
              </p>
            )}
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className={`w-full py-3 px-4 rounded-lg text-white font-medium ${
              isSubmitting 
                ? 'bg-blue-400 cursor-not-allowed' 
                : 'bg-blue-600 hover:bg-blue-700'
            } transition-colors duration-200`}
          >
            {isSubmitting ? 'Submitting...' : 'Get Your Free Playbook'}
          </button>
        </form>
      </div>
    </div>
  );
};