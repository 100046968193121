import React from 'react';
import { Users, Target, TrendingUp } from 'lucide-react';

export const StatsSection = () => {
  const stats = [
    { icon: Users, value: '500+', label: 'Satisfied Clients' },
    { icon: Target, value: '95%', label: 'Success Rate' },
    { icon: TrendingUp, value: '2.5x', label: 'Average ROI' },
  ];

  return (
    <section className="py-16 px-4 sm:px-6 lg:px-8 bg-white">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {stats.map(({ icon: Icon, value, label }) => (
            <div key={label} className="flex flex-col items-center text-center p-6">
              <div className="mb-4 p-3 bg-blue-50 rounded-full">
                <Icon className="w-8 h-8 text-blue-600" />
              </div>
              <div className="text-4xl font-bold text-gray-900 mb-2">{value}</div>
              <div className="text-gray-600">{label}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};