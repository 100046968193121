import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface FAQ {
  question: string;
  answer: string;
}

export const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const faqs: FAQ[] = [
    {
      question: "How does AI improve lead generation?",
      answer: "Our AI technology analyzes vast amounts of data to identify patterns and predict high-quality leads. It uses machine learning algorithms to understand customer behavior, preferences, and intent, resulting in more accurate lead targeting and higher conversion rates."
    },
    {
      question: "What makes your approach different from traditional lead generation?",
      answer: "Unlike traditional methods, our AI-powered approach provides real-time lead scoring, predictive analytics, and automated qualification processes. This results in faster, more accurate lead identification and higher quality prospects for your business."
    },
    {
      question: "How long does it take to see results?",
      answer: "Most clients start seeing improvements in lead quality within the first month. However, as our AI systems learn and adapt to your specific business needs, results typically optimize significantly over 3-6 months, leading to continuously improving outcomes."
    },
    {
      question: "Can AI lead generation work for my industry?",
      answer: "Yes! Our AI systems are adaptable to various industries and business models. We customize our approach based on your specific industry requirements, target audience, and business goals to ensure optimal results."
    },
    {
      question: "How do you ensure the quality of leads?",
      answer: "Our AI systems use multiple data points and verification methods to score and qualify leads. We continuously monitor and refine our algorithms based on feedback and conversion data to maintain high lead quality standards."
    }
  ];

  return (
    <section className="py-20 px-4 sm:px-6 lg:px-8 bg-gray-50">
      <div className="max-w-4xl mx-auto">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 text-gray-900">Frequently Asked Questions</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Get answers to common questions about our AI-powered lead generation services
          </p>
        </div>
        
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div 
              key={index}
              className="border border-gray-200 rounded-lg bg-white shadow-sm hover:shadow-md transition-all duration-300"
            >
              <button
                className="w-full px-6 py-4 text-left flex justify-between items-center"
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
              >
                <span className="font-semibold text-gray-900">{faq.question}</span>
                {openIndex === index ? (
                  <ChevronUp className="w-5 h-5 text-gray-500" />
                ) : (
                  <ChevronDown className="w-5 h-5 text-gray-500" />
                )}
              </button>
              {openIndex === index && (
                <div className="px-6 pb-4 text-gray-600 leading-relaxed">
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
