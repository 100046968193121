import React from 'react';
import { ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';

interface HeroSectionProps {
  onLeadMagnetClick: () => void;
}

export const HeroSection: React.FC<HeroSectionProps> = ({ onLeadMagnetClick }) => {
  return (
    <section className="relative min-h-screen pt-32 pb-32 px-4 sm:px-6 lg:px-8 overflow-hidden flex items-center">
      <div className="absolute inset-0 bg-gradient-to-br from-blue-900 via-blue-800 to-blue-900">
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1557426272-fc759fdf7a8d?ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80')] mix-blend-overlay opacity-20"></div>
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="flex flex-col lg:flex-row items-center gap-16">
          <div className="flex-1 text-center lg:text-left">
            <div className="inline-flex items-center px-4 py-2 bg-blue-800/30 rounded-full mb-8 backdrop-blur-sm border border-blue-400/20">
              <ChevronRight className="w-4 h-4 mr-2 text-blue-300" />
              <span className="text-blue-200 text-sm">AI-Powered Solutions</span>
            </div>
            <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-100 leading-tight">
              Transform Your <br className="hidden lg:block" />
              Lead Generation <br className="hidden lg:block" />
              With AI
            </h1>
            <p className="text-xl md:text-2xl mb-12 text-blue-100 max-w-2xl mx-auto lg:mx-0 leading-relaxed">
              Unlock the power of artificial intelligence to generate high-quality, qualified leads and accelerate your business growth.
            </p>
            <div className="flex flex-col sm:flex-row items-center justify-center lg:justify-start gap-6">
              <a
                href="https://tidycal.com/hyperlinq/30-minutes-free-good-sales-meeting"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center justify-center px-8 py-3 text-base font-medium 
                  text-white bg-white/10 
                  border border-white/20 
                  rounded-full 
                  backdrop-blur-md 
                  hover:bg-white/20 
                  transition-all duration-300 
                  transform hover:-translate-y-1 
                  shadow-lg shadow-blue-500/20 
                  hover:shadow-xl hover:shadow-blue-500/30"
              >
                Get Free Strategy Session
                <ChevronRight 
                  className="ml-2 w-4 h-4 text-blue-200 transform group-hover:translate-x-1 transition-transform" 
                />
              </a>
              <button
                onClick={onLeadMagnetClick}
                className="text-blue-100 hover:text-white flex items-center gap-2 group"
              >
                Download Free Playbook
                <ChevronRight className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
              </button>
            </div>
          </div>
          <div className="flex-1 hidden lg:block">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-br from-blue-600/20 to-blue-900/20 backdrop-blur-sm rounded-3xl transform rotate-6"></div>
              <img
                src="https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
                alt="AI Lead Generation"
                className="rounded-3xl shadow-2xl relative z-10"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};