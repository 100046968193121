import React, { useState } from 'react';
import { Mail, AlertCircle, ChevronRight, Loader2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabaseClient';
import { toast } from 'sonner';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email.trim()) {
      setError('Email is required');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.trim())) {
      setError('Please enter a valid email address');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const { error: resetError } = await supabase.auth.resetPasswordForEmail(email.trim(), {
        redirectTo: `${window.location.origin}/reset-password`,
      });

      if (resetError) {
        setError(resetError.message);
        return;
      }

      setEmailSent(true);
      toast.success('Password reset instructions have been sent to your email');
    } catch (error: any) {
      console.error('Password reset error:', error);
      setError(error.message || 'An unexpected error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-50 to-blue-100 px-4 py-12">
      <div className="w-full max-w-md">
        <div className="bg-white shadow-2xl rounded-2xl overflow-hidden">
          <div className="p-8 space-y-6">
            <div className="text-center">
              <a 
                href="/" 
                className="inline-block mb-4"
              >
                <img 
                  src="/src/logo/6.svg" 
                  alt="Cipher Leads" 
                  className="h-24 w-auto mx-auto"
                />
              </a>
              <p className="text-gray-500 text-sm">
                Reset your password
              </p>
            </div>

            {emailSent ? (
              <div className="text-center space-y-4">
                <div className="bg-green-50 border border-green-200 text-green-700 px-4 py-3 rounded-lg">
                  <p>Check your email for password reset instructions.</p>
                </div>
                <button
                  onClick={() => navigate('/login')}
                  className="w-full flex justify-center items-center py-3 px-4 rounded-lg text-white font-semibold bg-blue-600 hover:bg-blue-700 transition duration-300"
                >
                  Return to Login
                  <ChevronRight className="h-5 w-5 ml-2" />
                </button>
              </div>
            ) : (
              <form onSubmit={handleResetPassword} className="space-y-6">
                <div className="space-y-4">
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Mail className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300"
                      required
                    />
                  </div>
                </div>

                {error && (
                  <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg flex items-center space-x-3">
                    <AlertCircle className="h-5 w-5 text-red-500" />
                    <span className="text-sm">{error}</span>
                  </div>
                )}

                <button
                  type="submit"
                  disabled={isLoading}
                  className={`w-full flex justify-center items-center py-3 px-4 rounded-lg text-white font-semibold transition duration-300 space-x-2 
                    ${isLoading 
                      ? 'bg-blue-400 cursor-not-allowed' 
                      : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                    }`}
                >
                  {isLoading ? (
                    <>
                      <Loader2 className="mr-2 h-5 w-5 animate-spin" />
                      Sending Instructions...
                    </>
                  ) : (
                    <>
                      Reset Password
                      <ChevronRight className="h-5 w-5 ml-2" />
                    </>
                  )}
                </button>
              </form>
            )}
          </div>

          <div className="bg-blue-50 px-8 py-4 text-center text-sm text-gray-600">
            Remember your password?{' '}
            <a href="/login" className="text-blue-600 hover:text-blue-800 ml-1 font-semibold">
              Sign In
            </a>
          </div>
        </div>

        <div className="mt-6 text-center text-gray-500 text-xs">
          {new Date().getFullYear()} Cipher Leads. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
