import React from 'react';
import { CTAButton } from './CTAButton';
import { ArrowRight } from 'lucide-react';

interface CTASectionProps {
  onLeadMagnetClick: () => void;
}

export const CTASection = ({ onLeadMagnetClick }: CTASectionProps) => {
  return (
    <section className="py-20 px-4 sm:px-6 lg:px-8 bg-gradient-to-br from-blue-900 via-blue-800 to-blue-900 relative overflow-hidden">
      <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1557426272-fc759fdf7a8d?ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80')] mix-blend-overlay opacity-10"></div>
      <div className="max-w-7xl mx-auto text-center relative">
        <h2 className="text-3xl md:text-4xl font-bold mb-6 text-white">Ready to Transform Your Lead Generation?</h2>
        <p className="text-xl text-blue-100 mb-8 max-w-2xl mx-auto">
          Book your free strategy session now and discover how our AI-powered solutions can help you generate more qualified leads.
        </p>
        <div className="flex flex-col sm:flex-row items-center justify-center gap-6">
          <CTAButton 
            text="Book Free Strategy Call" 
            className="text-lg px-12 py-4"
          />
          <button
            onClick={onLeadMagnetClick}
            className="text-blue-100 hover:text-white flex items-center gap-2 group"
          >
            Get Free Playbook
            <ArrowRight className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
          </button>
        </div>
      </div>
    </section>
  );
};