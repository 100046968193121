import React from 'react';

interface CTAButtonProps {
  text: string;
  className?: string;
  variant?: 'primary' | 'white';
}

export const CTAButton = ({ text, className = '', variant = 'primary' }: CTAButtonProps) => {
  const baseStyles = "inline-block px-8 py-3 rounded-full font-semibold transition-all duration-300";
  const variants = {
    primary: "bg-blue-600 text-white hover:bg-blue-700",
    white: "bg-white text-blue-600 hover:bg-blue-50 shadow-lg hover:shadow-xl"
  };

  return (
    <a
      href="https://tidycal.com/hyperlinq/30-minutes-free-good-sales-meeting"
      className={`${baseStyles} ${variants[variant]} ${className}`}
    >
      {text}
    </a>
  );
};