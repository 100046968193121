import { createClient } from '@supabase/supabase-js';
import { v4 as uuidv4 } from 'uuid';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false,
  },
  global: {
    headers: {
      'x-client-info': 'aicipherleads',
    },
  },
  db: {
    schema: 'public'
  },
  realtime: {
    params: {
      eventsPerSecond: 1
    }
  }
});

// Utility function to validate UUID
export const isValidUUID = (uuid: string): boolean => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
};

// Enhanced type definition for leads
export interface Lead {
  id: string;
  name: string;
  email: string;
  phone?: string | null;
  source?: string;
  status?: string;
  user_id: string;
  created_at?: string;
  updated_at?: string;
}

// Create a new lead with enhanced error handling
export const createLead = async (leadData: Partial<Lead>) => {
  try {
    // Try to get the current user (optional)
    const { data: { user } } = await supabase.auth.getUser();

    const newLead: Partial<Lead> = {
      ...leadData,
      source: leadData.source || 'website',
      status: leadData.status || 'new',
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      // Only add user_id if a user is authenticated
      ...(user ? { user_id: user.id } : {})
    };

    // Remove undefined values
    Object.keys(newLead).forEach(key => 
      newLead[key as keyof Lead] === undefined && delete newLead[key as keyof Lead]
    );

    const { data, error } = await supabase
      .from('leads')
      .insert(newLead)
      .select()
      .single();

    if (error) {
      console.error('Lead Creation Error:', {
        message: error.message,
        details: error.details,
        hint: error.hint,
        code: error.code
      });
      throw error;
    }

    return { data, error: null };
  } catch (error: any) {
    console.error('Unexpected Lead Creation Error:', error);
    return { 
      data: null, 
      error: error.message || 'An unexpected error occurred while creating the lead' 
    };
  }
};

// Update a lead with comprehensive error handling
export const updateLead = async (leadId: string, updateData: Partial<Lead>) => {
  try {
    // Validate input
    if (!leadId) {
      throw new Error('Lead ID is required');
    }

    // Prepare update data
    const leadUpdateData = {
      ...updateData,
      updated_at: new Date().toISOString()
    };

    // Remove undefined values
    Object.keys(leadUpdateData).forEach(key => 
      leadUpdateData[key as keyof Lead] === undefined && 
      delete leadUpdateData[key as keyof Lead]
    );

    // Perform update with error handling
    const { data, error } = await supabase
      .from('leads')
      .update(leadUpdateData)
      .eq('id', leadId)
      .select();

    // Detailed error logging
    if (error) {
      console.error('Lead Update Error:', {
        message: error.message,
        details: error.details,
        hint: error.hint,
        code: error.code
      });
      throw error;
    }

    // Ensure data is an array and has exactly one item
    if (!data || data.length === 0) {
      throw new Error('No lead found with the given ID');
    }

    if (data.length > 1) {
      console.warn('Multiple leads found with the same ID. Using the first one.');
    }

    return { data: data[0], error: null };
  } catch (error: any) {
    console.error('Unexpected Lead Update Error:', error);
    return { 
      data: null, 
      error: error.message || 'An unexpected error occurred while updating the lead' 
    };
  }
};

// Delete a lead with comprehensive error handling
export const deleteLead = async (leadId: string) => {
  try {
    // Validate input
    if (!leadId) {
      throw new Error('Lead ID is required');
    }

    // Perform delete with error handling
    const { data, error } = await supabase
      .from('leads')
      .delete()
      .eq('id', leadId)
      .select();

    // Detailed error logging
    if (error) {
      console.error('Lead Deletion Error:', {
        message: error.message,
        details: error.details,
        hint: error.hint,
        code: error.code
      });
      throw error;
    }

    return { data, error: null };
  } catch (error: any) {
    console.error('Unexpected Lead Deletion Error:', error);
    return { 
      data: null, 
      error: error.message || 'An unexpected error occurred while deleting the lead' 
    };
  }
};

// Get leads for the current user with pagination
export const getLeads = async (page: number = 1, pageSize: number = 10) => {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    
    if (!user) {
      throw new Error('User not authenticated');
    }

    const start = (page - 1) * pageSize;
    const end = start + pageSize - 1;

    // Fetch leads with error handling
    const { data, error, count } = await supabase
      .from('leads')
      .select('*', { count: 'exact' })
      .eq('user_id', user.id)
      .order('created_at', { ascending: false })
      .range(start, end);

    if (error) {
      console.error('Leads Fetch Error:', error);
      throw error;
    }

    return {
      leads: data || [],
      totalCount: count || 0,
      page,
      pageSize
    };
  } catch (error) {
    console.error('Get Leads Error:', error);
    throw error;
  }
};
