import React from 'react';
import { Quote } from 'lucide-react';

interface Testimonial {
  quote: string;
  author: string;
  position: string;
  company: string;
}

export const TestimonialSection = () => {
  const testimonials: Testimonial[] = [
    {
      quote: "The AI-powered lead generation has transformed our business. We've seen a 300% increase in qualified leads within just 3 months.",
      author: "Sarah Johnson",
      position: "Marketing Director",
      company: "TechGrowth Inc."
    },
    {
      quote: "Their innovative approach to lead generation has helped us reach markets we never thought possible. The results speak for themselves.",
      author: "Michael Chen",
      position: "Sales Manager",
      company: "InnovateX Solutions"
    },
    {
      quote: "Not only did they deliver exceptional leads, but their strategic insights helped us optimize our entire sales process.",
      author: "Emily Rodriguez",
      position: "CEO",
      company: "Digital Ventures"
    }
  ];

  return (
    <section className="py-20 px-4 sm:px-6 lg:px-8 bg-gradient-to-br from-blue-900 via-blue-800 to-blue-900">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 text-white">What Our Clients Say</h2>
          <p className="text-xl text-blue-100 max-w-2xl mx-auto">
            Success stories from businesses that transformed their lead generation with AI
          </p>
        </div>
        
        <div className="grid md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <div 
              key={index}
              className="bg-white/10 backdrop-blur-lg rounded-xl p-8 border border-blue-400/20 hover:border-blue-400/40 transition-all duration-300"
            >
              <Quote className="w-8 h-8 text-blue-300 mb-6" />
              <p className="text-blue-100 mb-6 text-lg leading-relaxed">"{testimonial.quote}"</p>
              <div>
                <p className="font-semibold text-white">{testimonial.author}</p>
                <p className="text-blue-200">{testimonial.position}</p>
                <p className="text-blue-300">{testimonial.company}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
